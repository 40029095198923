import React from 'react'
import { Link } from 'gatsby'

// markup
const NotFoundPage = () => {
  return (
    <main className='container min-h-screen pt-10'>
      <h1>404</h1>
      <p>Emme löytäneet etsimääsi sivua. <Link to='/' className='underline'>Palaa takaisin etusivulle</Link>.</p>
    </main>
  )
}

export default NotFoundPage
